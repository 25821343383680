<template>
    <div class="app-container">
      <div class="header-box">
        <div>
          <el-input placeholder="请输入科目编码或名称" v-model="listQuery.name" style="width: 200px;" size="small" clearable @keyup.enter="getList()" />
          <el-button size="small" type="primary" @click="getList()"  >
            <el-icon><Search /></el-icon><span  > 搜索</span>
          </el-button>
          <span v-if="comKj == '民办非'" style="margin-left:12px">
            <el-button plain size="small" type="primary" @click="getList(item.value)" v-for="(item,index) in typeO" :key="index" >{{item.name}}</el-button>
          </span>
          <span v-else-if="this.comKj == '一般企业会计准则(已执行新金融准则)(全科目)' || this.comKj == '一般企业会计准则(全科目)'" style="margin-left:12px">
            <el-button plain size="small" type="primary" @click="getList(item.value)" v-for="(item,index) in typesGtl" :key="index" >{{item.name}}</el-button>
          </span>
          <span v-else style="margin-left:12px">
            <el-button plain size="small" type="primary" @click="getList(item.value)" v-for="(item,index) in types" :key="index" >{{item.name}}</el-button>
          </span>
        </div>
        <div>
          <!-- <qzf-video vid="4467be537471dbc14d63f148915094fb_4"></qzf-video> -->
  
          <!-- <el-button size="small" type="primary" plain @click="plDelete">批量删除</el-button> -->
          <el-dropdown size="small" style="margin-left:10px" :hide-on-click="false">
          <el-button size="small" type="primary">
              更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu style="text-align:center">
                <!-- <el-dropdown-item command="c" v-if="$buttonStatus('kjkm_dr')">
                  <span @click="importSubject">导入</span>
                </el-dropdown-item> -->
                <el-dropdown-item command="a" v-if="$buttonStatus('kjkm_plsc')">
                  <span @click="plDelete">批量删除</span>
                </el-dropdown-item>
                <el-dropdown-item command="b" v-if="$buttonStatus('kjkm_bqkm')">
                  <span @click="comAccounts">补全科目</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
     
      <n-data-table
        ref="tableDate"
        :columns="getTableColumns()"
        :data="list"
        :max-height="contentStyleObj"
        :row-key="row => row.id"
        :indent="14"
        :loading="loading"
        :scroll-x="1370"
        :checked-row-keys="checkedRowKeys"
        :on-update:checked-row-keys="selectChangeEvent"
        :row-props="() => ({style: { height: '40px' }})"
        :single-line="false"
        virtual-scroll
        bordered
        striped
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
      </n-data-table>
      <!-- 编辑科目弹窗 -->
      <el-dialog destroy-on-close  title="编辑科目" v-model="dialogVisibleEdit" width="25%">
        <el-form :model="editData" label-width="100px" label-position="right">
          <el-form-item  label="科目编码：">
            <el-input disabled style="width:200px" v-model="editData.code" size="small" ></el-input>
          </el-form-item>
          <el-form-item  label="科目名称：">
            <el-input style="width:200px" v-model="editData.name" size="small"></el-input>
          </el-form-item>
          <el-form-item  label="余额方向：">
            <el-radio v-model="editData.type" :label="1" size="small">借</el-radio>
            <el-radio v-model="editData.type" :label="2" size="small">贷</el-radio>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleEdit = false" size="small">取 消</el-button>
            <el-button type="primary" @click="handleEditSure" size="small">确 定</el-button>
          </span>
        </template>
      </el-dialog>
  
      <!-- 绑定辅助核算弹窗 -->
      <el-dialog destroy-on-close title="绑定辅助核算" v-model="dialogVisibleBind" width="550px">
        <el-form :model="editData" label-width="100px" label-position="right">
          <el-form-item  label="科目编码：">
            <el-input disabled style="width:200px" v-model="editData.code" size="small" ></el-input>
          </el-form-item>
          <el-form-item  label="科目名称：">
            <el-input disabled style="width:200px" v-model="editData.name" size="small"></el-input>
          </el-form-item>
          <el-form-item  label="余额方向：">
            <el-radio disabled v-model="editData.type" :label="1" size="small">借</el-radio>
            <el-radio disabled v-model="editData.type" :label="2" size="small">贷</el-radio>
          </el-form-item>
          <el-form-item label="核算方式：">
            <el-checkbox v-model="checked" label="辅助核算"></el-checkbox>
            <i class="iconfont icon-jiahao" @click="saProject" style="color: #23a5c3;font-size: 18px;padding-left: 10px;cursor: pointer;"></i>
          </el-form-item>
          <el-form-item label="辅助核算：" v-if="checked">
            <el-radio-group v-model="editData.fzhsId">
              <el-radio v-for="(item,index) in dataList" :key="index" :label="item.id">
                <el-tooltip effect="dark" placement="top-start" :disabled="isShowTooltip" :content="item.name">
                  <span :ref="item.name" @mouseover="onMouseOver(item.name)">
                    {{item.name}}
                  </span>
                </el-tooltip>
                <!-- {{item.name}} -->
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="辅助核算项目名称：">
            <el-button type="primary" @click="saProject" size="small">添加</el-button>
          </el-form-item> -->
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="small" @click="dialogVisibleBind = false">取 消</el-button>
            <el-button size="small" type="primary" :loading="loading" @click="bingSubject">确 定</el-button>
          </span>
        </template>
      </el-dialog>
  
      <!-- 绑定外币弹窗 -->
      <el-dialog destroy-on-close title="绑定外币" v-model="dialogVisibleWbBind" width="30%">
        <el-form :model="wbData" label-width="100px" label-position="right">
          <el-form-item  label="科目编码：">
            <el-input disabled style="width:200px" v-model="wbData.code" size="small" ></el-input>
          </el-form-item>
          <el-form-item  label="科目名称：">
            <el-input disabled style="width:200px" v-model="wbData.name" size="small"></el-input>
          </el-form-item>
          <el-form-item label="币种：">
            <el-select v-model="wbData.wb" placeholder="请选择" size="small" style="width:200px">
              <el-option
                v-for="item in wbOptions"
                :key="item.id"
                :label="item.type"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="汇率制度：">
            <el-select v-model="rateValue" placeholder="请选择" size="small" @change="rateChange" style="width:200px">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="本期汇率：" v-if="rateValue == '固定汇率'">
            <el-input size="small" v-model="wbData.wbRate" style="width:200px"></el-input>
          </el-form-item>
          <el-button v-if="rateValue == '浮动汇率'" type="text" @click="dianji" size="small" style="margin-left:90px">点击查看人民币汇率中间价</el-button>
          <el-form-item>
            <el-checkbox v-model="remitChecked" label="期末调汇" @change="handleChange"></el-checkbox>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="small" @click="dialogVisibleWbBind = false">取 消</el-button>
            <el-button size="small" type="primary" :loading="loading" @click="bindWb">确 定</el-button>
          </span>
        </template>
      </el-dialog>
  
      <!-- 修改期初新增的 -->
      <el-dialog :close-on-click-modal="false" destroy-on-close title="新增科目" v-model="dialogVisibleStart" width="35%" >
        <el-form :model="addDataStart" label-width="120px" label-position="right" size="small">
          <el-form-item label="上级科目：">
            <el-input v-model="addDataStart.lastName" style="width:200px;" size="small" disabled></el-input>
          </el-form-item>
          <div>
            <el-form-item label="科目名称：">
              <el-input v-model="addDataStart.name" style="width:200px;" size="small"></el-input>
            </el-form-item>
            <el-form-item class="item" label="余额方向：">
              <el-radio v-model="addDataStart.type" :label="1" disabled>借</el-radio>
              <el-radio v-model="addDataStart.type" :label="2" disabled>贷</el-radio>
            </el-form-item>
          </div>
    
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleStart = false" size="small">取 消</el-button>
            <el-button type="primary" @click="handleAddSureStart" size="small">确 定</el-button>
          </span>
        </template>
      </el-dialog>
  
      <add-subject @success="getList()" :disabled="true" ref="addSubject"></add-subject>
      <!-- 补全科目弹窗 -->
      <bqkm ref="bqkm" @success="getList"></bqkm>
      <addProject ref="addProject" @success="fzhsList"></addProject>
      <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" @success2="getBusiness" from="会计科目"></qzf-import-back>
  
      
      <el-dialog
        v-model="dialogVisibleSubject"
        :title="this.eaSubjectName"
        width="430px"
      >
        <div style="font-size:13px;color:var(--themeColor, #17a2b8);margin-bottom:5px">*{{this.subjectErr}}</div>
        <div style="font-size:13px;margin-bottom:10px">若删除此科目，系统会将关联中的科目更新为：</div>
        <el-select size="small" v-model="this.eaSubjectId" placeholder="请选择" clearable style="width:100%" filterable>
          <el-option v-for="item in subjectOptions" :key="item.id" :label="item.name3" :value="item.id"></el-option>
        </el-select>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleSubject = false" size="small" type="primary">取消</el-button>
            <el-button @click="makeSureDelete" size="small" type="primary">确定</el-button>
          </span>
        </template>
      </el-dialog>
  
    </div>
  </template>
  
  <script lang="jsx">
  import { subjectTreeList , saveSubject , deleteEaSubject ,deleteEaSubjectV3,deleteMoveSubjectV3, fzhsList , subjectBindFzhs, subjectCancelBindFzhs ,subjectWbEdit , wbList ,delSubjectAllLower ,stopUseSubject,startUseSubject,deleteEaSubjectFromOut} from "@/api/subject"
  import bqkm from "./components/bqkm.vue"
  import addProject from "./components/addProject"
  
  // const deepChildren = (list, value, ids) => {
  //   list.forEach(l => {
  //     l.checked = value
  //     l.children && deepChildren(l.children, value)
  //     ids && ids.push(l.id)
  //   })
  // }
  
  export default {
    name:'subject',
    components:{ bqkm,addProject },
    data() {
      return{
        oldSelect: [],
        list: [],
        loading:false,
        listQuery:{
          id: 456,//456
          id2: 233,//233
        },
        typeO:[
        {
            name: "全部",
            value: ""
          },
          {
            name: "资产",
            value: '1'
          },
           {
            name: "负债",
            value: '2'
          },
           {
            name: "净资产",
            value: '3'
          },
           {
            name: "收入",
            value: '4'
          },
           {
            name: "费用",
            value: '5'
          },
        ],
        types: [
          {
            name: "全部",
            value: ""
          },
          {
            name: "资产",
            value: '1'
          },
           {
            name: "负债",
            value: '2'
          },
           {
            name: "权益",
            value: '3'
          },
           {
            name: "成本",
            value: '4'
          },
           {
            name: "损益",
            value: '5'
          },
        ],
        typesGtl: [
          {
            name: "全部",
            value: ""
          },
          {
            name: "资产",
            value: '1'
          },
           {
            name: "负债",
            value: '2'
          },
          {
            name: "共同类",
            value: '3'
          },
          {
            name: "权益",
            value: '4'
          },
          {
            name: "成本",
            value: '5'
          },
          {
            name: "损益",
            value: '6'
          },
        ], 
        dialogVisibleEdit:false,
        dialogVisibleBind:false,
        dialogVisibleWbBind:false,
        editData:{},
        wbData:{
          remittance:0
        },
        lastSubject:'',
        ids: [],
        checked:false,
        itemChecked:false,
        dataList:[],
        checkList:[],
        options:[
          {
            value:'固定汇率',
            label:'固定汇率'
          },
          {
            value:'浮动汇率',
            label:'浮动汇率'
          }
        ],
        wbOptions:[],
        rateValue:'',
        remitChecked:false,
        code: ['1285','1403','1405','1406','1408','1411','1412','1421','1605','code1621'],
        setScrollTop:'',
        comKj:this.$store.getters['user/comInfo'].comKj,
        start:true,
        addDataStart:{
          lastName:'',
          name:'',
          subjectId:0,
          type:1
        },
        dialogVisibleStart:false,
        dialogVisibleSubject:false,
        subjectOptions:[],
        eaSubjectId:0,
        subjectErr:'',
        eaSubjectName:'',
        oldSubjectId:0,
        isShowTooltip:true,
        // code: [],
        currentParentId: null,
        tableTreeRefreshTool: {},
        tableWidth: 0,
        checkedRowKeys: [],
      }
    },
    
    created(){
      this.contentStyleObj= parseInt(this.$getHeight(160)) - 48
      this.initBus()
      
    },
    mounted(){
      this.getWidth()
      // this.$refs.tableDate.$refs.bodyWrapper.addEventListener('scroll',(res) => {
      //   this.setScrollTop = res.target.scrollTop
      // },true)
      this.getList()
    },
    methods:{
      selectChangeEvent (keys) {
        this.checkedRowKeys = keys
        this.ids = this.checkedRowKeys
          .map(id => ({id}))
      },
      getWidth () {
        const container = document.querySelector('.app-main')
        const containerWidth = container.getBoundingClientRect()?.width
        this.tableWidth = containerWidth - 40
      },
      getTableColumns () {
        return [
          {
            type: 'selection',
            width: 50,
          },
          {
            key: 'code',
            title: '科目编码',
            minWidth: 200,
            align: 'left',
            tree: true,
          },
          {
            key: 'name',
            title: '科目名称',
            minWidth: 200,
            align: 'left',
            ellipsis: {},
            'ellipsis-component': 'ellipsis',
          },
          {
            key: 'type',
            title: '余额方向',
            dataKey: 'type',
            width: 80,
            align: 'center',
            render: (row) => {
              return <span>{ this.typeFilter(row.type) }</span>
            }
          },
          {
            key: 'fzhsName',
            title: '辅助核算',
            width: 80,
            minWidth: 80,
            align: 'center'
          },
          {
            key: 'wb',
            title: '外币',
            dataKey: 'wb',
            width: 80,
            minWidth: 80,
            align: 'center'
          },
          {
            key: 'opera',
            title: '操作',
            width: 515,
            align: 'center',
            fixed: 'right',
            render: (row) => {
              if (this.start) {
                const slots = () => (<qzf-button disabled={!this.$buttonStatus('kjkm_sc') || row.status == 2} size="small" text style="margin: 0;">删除</qzf-button>)
                return <>
                  {
                    row.status == 1 && <qzf-button disabled={!this.$buttonStatus('kjkm_xz')} size="small" text onSuccess={() => this.stopMade(row)}>禁用</qzf-button>
                  }
                  {
                    row.status != 1 && <qzf-button disabled={!this.$buttonStatus('kjkm_xz')} size="small" text onSuccess={() => this.startMade(row)}>启用</qzf-button>
                  }
                    <el-tooltip effect="dark" disabled={!row.children}  placement="top-start" v-slots={{
                      content:() => <div>已存在下级，请选择对应明细科目绑定外币</div>,
                      default:() => <qzf-button disabled={!this.$buttonStatus('kjkm_bdwb') || row.status == 2 || row.children} size="small" text onSuccess={() => this.wbBinding(row)} style="margin: 0;">绑定外币</qzf-button>
                    }}/>
                    <qzf-button disabled={!this.$buttonStatus('kjkm_bdfzhs') || row.status == 2} size="small" text onSuccess={() => this.binding(row)} style="margin: 0;">绑定辅助核算</qzf-button>
                    <el-tooltip effect="dark" disabled={!row.fzhsId}  placement="top-start" v-slots={{
                      content:() => <div>已开启辅助核算的科目不允许新增下级科目</div>,
                      default:() =><qzf-button disabled={!this.$buttonStatus('kjkm_xz') || row.status == 2 || row.fzhsId} size="small" text onSuccess={() => this.add(row)} style="margin: 0;">新增</qzf-button>
                    }}/>
                    <el-tooltip effect="dark" disabled={row.parentId}  placement="top-start" v-slots={{
                      content:() => <div>一级科目不允许修改</div>,
                      default:() => <qzf-button disabled={!this.$buttonStatus('kjkm_bj') || row.status == 2 || !row.parentId} size="small" text onSuccess={() => this.edit(row)} style="margin: 0;">编辑</qzf-button>
                    }}/>
                    <el-popconfirm title="确认删除该科目吗" onConfirm={() => this.deleteRow(row)} v-slots={{
                    reference: slots
                    }} />
                    <qzf-button disabled={!this.$buttonStatus('kjkm_sc') || row.status == 2} size="small" text onSuccess={() => this.deleteRowAllLow(row)} style="margin: 0;">删除所有下级科目</qzf-button>
                    
                </>
              } else {
                const slots = () => <el-button size="small" text>删除</el-button>
                return <>
                  <el-button size="small" text onClick={() => this.addStart(row)}>新增</el-button>
                  <el-popconfirm title="确认删除该科目吗" onConfirm={() => this.deleteStart(row)} v-slots={{
                    reference: slots
                  }} />
                </>
              }
            }
          },
        ]
      },
      initBus(){
        this.$bus.off("eaSubjectUpdate")
        this.$bus.on("eaSubjectUpdate", (val) => {
          this.getList()
        });
      },
      getList(val,comId,start){
        if(comId){
          this.listQuery.comId = comId
        }
        if(start == 'start'){
          this.start = false
        }
        this.loading = true
        this.listQuery.type2 = val
        // if(this.list.length != 0){
        //   this.$refs.tableDate.scrollTo({
        //     top: this.setScrollTop
        //   })
        // }
        subjectTreeList(this.listQuery).then(res=>{
          this.list = res.data.data.list ? res.data.data.list : []
          
          // this.changeFilterSubject(this.list)
          this.loading = false
          let newCode = this.list.filter((item)=>{
            return !item.code.indexOf("5",0)
          })
          newCode.map(v=>{
            this.code.push(v.code)
          })
        })
        // todo children里的还是会显示
      },
      //添加辅助核算项目名称
      saProject() {
        this.$refs.addProject.openDialog()
      },
      typeFilter(type){
        if(type == 1){
          return "借"
        }else if(type == 2){
          return "贷"
        }
      },
      add(row){
        let data = {
          type: row.type,
          id: row.id,
          name: '',
          code:row.code
        }
        this.$refs.addSubject.init(data, [])
      },
      // 修改期初的
      addStart(row){
        this.addDataStart.lastName = row.name3
        this.addDataStart.subjectId = row.id
        this.addDataStart.comId = row.comId
        this.addDataStart.type = row.type
        this.dialogVisibleStart = true
      },
      deleteStart(row){
      ///  //console.log(row);
        let param = {
          comId:row.comId,
          subjectId:row.id
        }
        deleteEaSubjectFromOut(param).then(res => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功")
            this.getList("",this.addDataStart.comId,"start")
            this.$emit('success')
          }
        })
      },
      handleAddSureStart(){
        if(!this.addDataStart.name){
          this.$qzfMessage("请输入科目名称", 1);
          return
        }
        saveSubject(this.addDataStart).then(res => {
          if (res.data.msg == "success") {
            this.$qzfMessage("新增成功")
            this.dialogVisibleStart = false
            this.getList("",this.addDataStart.comId,"start")
            this.$emit('success')
          }
        });
      },
      edit(row){
        this.dialogVisibleEdit = true
        this.editData.type = row.type
        this.editData.code = row.code
        this.editData.name = row.name
        this.editData.id = row.id
      },
      handleEditSure() {
        if(!this.editData.name){
          this.$qzfMessage("请输入科目名称", 1);
          return
        }
        saveSubject(this.editData).then(res => {
          if (res.data.msg == "success") {
            this.dialogVisibleEdit = false;
            this.$qzfMessage("修改成功")
            this.getList()
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getCorp")
            this.$store.dispatch("commons/getBusinessType")
          }else {
            this.$message.error(res.data.msg);
          }
        });
      },
      deleteRow(id){
        this.loading = true
        deleteEaSubjectV3(id).then(res=>{
          if(res.data.msg == "success"){
            this.loading = false
            if(res.data.data.err){
              this.oldSubjectId = id.id
              this.eaSubjectName = id.name3
              this.subjectErr = res.data.data.err.split('，')[0]
              // 弹框
              this.subjectOptions = res.data.data.list
              this.eaSubjectId = this.subjectOptions[0].id
              this.dialogVisibleSubject = true
  
            }else{
              this.$qzfMessage("删除成功")
              this.listQuery = {}
              this.getList()
              this.$store.dispatch("commons/getSubjects")
              this.$store.dispatch("commons/getBusinessType")
              this.$store.dispatch("commons/getCorp")
            }
          }else{
            this.loading = false
          }
        })
          // deleteEaSubject([id]).then(res=>{
          //   if(res.data.msg == "success"){
          //     this.$qzfMessage("删除成功")
          //     this.listQuery = {}
          //     this.getList()
          //     this.$store.dispatch("commons/getSubjects")
          //     this.$store.dispatch("commons/getBusinessType")
          //     this.$store.dispatch("commons/getCorp")
          //   }else{
          //     this.loading = false
          //   }
          // })
        // })
      },
      makeSureDelete(){
       // //console.log(this.eaSubjectId);
        let param = {
          subjectId:this.oldSubjectId,
          subjectMoveId:this.eaSubjectId,
        }
        deleteMoveSubjectV3(param).then(res => {
          if(res.data.msg == "success"){
            this.dialogVisibleSubject = false
            this.$qzfMessage("删除成功")
            this.listQuery = {}
            this.getList()
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getBusinessType")
            this.$store.dispatch("commons/getCorp")
          }
        })
      },
      plDelete(){
        if(this.ids.length == 0){
          this.$qzfMessage("请至少选择一条",2)
          return
        }
        this.$confirm('是否确认删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          deleteEaSubject(this.ids).then(res=>{
            if(res.data.msg == "success"){
              this.checkedRowKeys = []
              this.$qzfMessage("删除成功")
              this.listQuery = {}
              this.getList()
              this.$store.dispatch("commons/getSubjects")
              this.$store.dispatch("commons/getBusinessType")
              this.$store.dispatch("commons/getCorp")
  
            }else{
              this.loading = false
            }
          })
        })
      },
      //补全科目
      comAccounts() {
        this.$refs.bqkm.init()
      },
      //辅助核算list
      fzhsList() {
        fzhsList({}).then(res=>{
          this.dataList = res.data.data.list
        })
      },
      // 绑定辅助核算
      binding(row){
        this.editData.type = row.type
        this.editData.code = row.code
        this.editData.name = row.name
        this.editData.fzhsId = row.fzhsId
        this.checked = Boolean(this.editData.fzhsId)
        this.editData.subjectId = row.id
        this.dialogVisibleBind = true
        fzhsList({}).then(res=>{
          this.dataList = res.data.data.list
        })
      },
      bingSubject(){
        //绑定
        if(this.checked && this.editData.fzhsId){
          this.loading = true
          subjectBindFzhs(this.editData).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              if(res.data.data.info){
                this.$qzfMessage('请稍后刷新查看...',3)
              } else {
                this.$qzfMessage('绑定成功')
              }
              this.$store.dispatch("commons/getSubjects")
              this.$store.dispatch("commons/getBusinessType")
              this.$bus.emit('fzhsSubjectListUpdate')
              this.$bus.emit('voucherUpdate')
              this.$bus.emit('invioceFeeUpdate')
              this.$bus.emit('invioceInUpdate')
              this.$bus.emit('invioceOutUpdate')
              this.$bus.emit('bankUpdate')
              this.$bus.emit('cashUpdate')
              this.$bus.emit('billUpdate')
              this.dialogVisibleBind = false
              this.getList()
            }
          })
        }else{
          this.loading = true
          subjectCancelBindFzhs(this.editData).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              if(res.data.data.info){
                this.$qzfMessage('请稍后刷新查看...',3)
              } else {
                this.$qzfMessage('解绑成功')
              }
              this.$store.dispatch("commons/getSubjects")
              this.$store.dispatch("commons/getBusinessType")
              this.$bus.emit('fzhsSubjectListUpdate')
              this.$bus.emit('voucherUpdate')
              this.$bus.emit('invioceFeeUpdate')
              this.$bus.emit('invioceInUpdate')
              this.$bus.emit('invioceOutUpdate')
              this.$bus.emit('bankUpdate')
              this.$bus.emit('cashUpdate')
              this.$bus.emit('billUpdate')
              this.dialogVisibleBind = false
              this.getList()
            }
          })
        }
      },
      // 绑定外币
      wbBinding(row){
       /// //console.log(row);
        this.wbData = {
          remittance:0
        }
        this.wbData.code = row.code
        this.wbData.name = row.name
        this.wbData.id = row.id
        this.wbData.wb = row.wb
        this.wbData.wbRate = row.wbRate
        if(row.wbRate){
          this.rateValue = '固定汇率'
        }else{
          this.rateValue = '浮动汇率'
        }
        this.dialogVisibleWbBind = true
        wbList({}).then(res=>{
          this.wbOptions = res.data.data.list
        })
      },
      handleChange(){
        if(this.remitChecked == true){
          this.wbData.remittance = 1
        }else{
          this.wbData.remittance = 0
        }
      },
      bindWb(){
        this.loading = true
        subjectWbEdit(this.wbData).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("绑定成功")
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getBusinessType")
            this.dialogVisibleWbBind = false
            this.getList()
          }
        })
      },
      rateChange(){
        if(this.rateValue == '浮动汇率'){
          this.wbData.wbRate = ''
        }
      },
      dianji(){
        window.open('https://www.chinamoney.com.cn/chinese/bkccpr/')
      },
      importSubject(){
        this.$refs.importBck.handImport()
      },
      // handleSelectionChange(val, raw) {
      //   this.oldSelect = []
      //   this.ids = []
      //   this.oldSelect.map(row=>{
      //     if(row.id != raw.id){
      //       this.$refs.tableDate.toggleRowSelection(row,true);
      //     }
      //   })
      //   val.map(v=>{
      //     this.oldSelect.push(v)
      //   })
      //   this.ids = this.$refs.tableDate.getSelectionRows().map(v=>{
      //     return {id: v.id}
      //   })
      // },
      deleteRowAllLow(row){
        this.$confirm('删除后不可恢复！是否删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          delSubjectAllLower({id:row.id}).then(res => {
            if(res.data.msg == "success"){
              this.$qzfMessage("删除成功")
              this.listQuery = {}
              this.getList()
              this.$store.dispatch("commons/getSubjects")
              this.$store.dispatch("commons/getBusinessType")
              this.$store.dispatch("commons/getCorp")
            }else{
              this.getList()
            }
          })
        })
        
      },
      getBusiness(){
        this.$store.dispatch("commons/getBusinessType")
        this.getList()
      },
      // 禁用
      stopMade(row){
        stopUseSubject(row).then(res => {
           if(res.data.msg == "success"){
              this.$qzfMessage("禁用成功")
              this.getList()
              this.$store.dispatch("commons/getSubjects")
              this.$store.dispatch("commons/getBusinessType")
           }
        })
      },
      startMade(row){
        startUseSubject(row).then(res => {
          if(res.data.msg == "success"){
              this.$qzfMessage("启用成功")
              this.getList()
              this.$store.dispatch("commons/getSubjects")
              this.$store.dispatch("commons/getBusinessType")
           }
        })
      },
      onMouseOver(str){
        let contentWidth = this.$refs[str].offsetWidth;
          // 判断是否开启tooltip功能
        if (contentWidth > 125) {
          this.isShowTooltip = false;
        } else {
          this.isShowTooltip = true;
        }
        
      },
  // 过滤只留一个
      // changeFilterSubject(list) {
      //   for (let i = 0; i < list.length; i++) {
      //     if(list[i].children){
      //       list[i].children = [list[i].children[0]]
      //       if (list[i].children[0].children){
      //         this.changeFilterSubject(list[i].children);
      //       }
      //     }
      //   }
      // },
      // handleExpandChange(row, expandedRows){
      //   if(expandedRows){
      //     let param = {
      //       id:456,
      //       id2:233,
      //       name:row.code
      //     }
      //     subjectTreeList(param).then(res => {
      //       let childrenList = res.data.data.list
      //       let childrenList2 = childrenList.filter(v => {
      //         return v.code == row.code
      //       })
      //       this.list.map(v => {
      //         if(v.code == row.code){
      //           console.log(v);
      //           v.children = childrenList2[0].children
      //         }
      //       })
      //     })
      //   }
      // },
      // load(tree, treeNode, resolve) {
      //   console.log(tree.id);
      //     setTimeout(() => {
      //       resolve([
      //         {
      //           id: 31,
      //           date: '2016-05-01',
      //           name: '王小虎',
      //           address: '上海市普陀区金沙江路 1519 弄'
      //         }, {
      //           id: 32,
      //           date: '2016-05-01',
      //           name: '王小虎',
      //           address: '上海市普陀区金沙江路 1519 弄'
      //         }
      //       ])
      //     }, 1000)
      //   },
      
      // changeSelect(a,row){
      //   //console.log(this.$refs.tableDate.store.isSelected(row));
      //   if(this.$refs.tableDate.store.isSelected(row)){
      //     if(row.children){
      //       this.$refs.tableDate.toggleRowSelection(row,false)
      //       for(let i =0 ;i<row.children.length;i++){
      //         this.$refs.tableDate.toggleRowSelection(row.children[i],true)
      //       }
      //     }
      //   }
      // }
    }
  
  }
  </script>
  
  <style lang="scss" scoped>
  .header-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .top_right_btns {
    float: right;
    margin-right: 15px;
    i {
      margin: 0 5px;
      cursor: pointer;
      color: var(--themeColor,#17a2b8);
      font-size: 18px;
      line-height: 24px;
      // font-weight: 600;
    }
  }
  .el-select{
    margin: 0px;
  }
  .checkRate{
    cursor:pointer;
  }
  .checkRate:hover{
    color: var(--themeColor,#17a2b8);
  }
  :deep(.el-radio__label) {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
  </style>