<template>
  <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="Ai财务工厂设置" name="1">
      <div style="width:850px">
        <div class="right_title none-border">
          <h3>启动设置</h3>
          <p>
            <span style="color: #2c3e50;">记账报税启动日期：</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <el-select size="small" v-model="info.autoStartTime" filterable placeholder="请选择" class="w-140" clearable
              @clear="info.autoStartTime = null">
              <el-option v-for="n in range(2, 10)" :key="n" :label="'每月' + n.toString() + '日'" :value="n"></el-option>
            </el-select>
            <span style="color:red;padding-left:10px">*设置启动日期将按照启动日期启动不根据节假日顺延</span>
          </p>
        </div>
        <div class="right_title none-border">
          <h3>超时确认</h3>
          <p>
            <span style="color: #2c3e50;">是否启动超时确认功能：</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <el-select size="small" disabled v-model="info.chaoshiqr" filterable placeholder="请选择" class="w-140">
              <el-option label="启动超时确认" :value="1"></el-option>
              <el-option label="关闭超时确认" :value="0"></el-option>
            </el-select>
            <span style="color:red;padding-left:10px">{{
              !info.chaoshiqr ? '*系统将不会自动确认任务，请注意不要漏报' :'*系统将于每月10日(法定节假日顺延)，自动确认待确认任务，并进行下一步任务' }} </span>
          </p>
        </div>
        <div class="right_title none-border">
          <h3>社保申报往期</h3>
          <p>
            <span style="color: #2c3e50;">社保申报是否申报往期：</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <el-select size="small" v-model="info.shebaosbwq" filterable placeholder="请选择" class="w-140">
              <el-option label="不申报" :value="0"></el-option>
              <el-option label="申报" :value="1"></el-option>
            </el-select>
          </p>
        </div>
        <div style="margin-top: 20px; text-align: right;">
          <el-button type="primary" @click="saveInfo" size="small" :disabled="!$buttonStatus('xgsz_bc')"
            :loading="oneLoading"><i class="iconfont icon-baocun"></i>保存</el-button>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { orgSettingInfo, orgSettingDaySave } from "@/api/org";
import { ElMessage } from 'element-plus';
const { proxy } = getCurrentInstance()
const info = ref({})
const activeName = ref("1")
onMounted(() => {
  getInfo()
})
const oneLoading = ref(false)
const getInfo = () => {
  orgSettingInfo({}).then(res => {
    if (res.data.msg == "success") {
      info.value = res.data.data
      info.value.autoStartTime = res.data.data.autoStartTime ? res.data.data.autoStartTime : null
    }
  })
}
const saveInfo = () => {
  oneLoading.value = true
  info.value.cjInvoiceInType = info.value.cjInvoiceInType.toString()
  orgSettingDaySave(info.value).then((res) => {
    oneLoading.value = false;
    if (res.data.msg == "success") {
      ElMessage.success("修改成功")
      getInfo();
      proxy.$bus.emit('confirmStatusUpdate')
    }
  });
}
const range = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};
</script>

<style scoped lang="scss">
.right_title {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;

  h3 {
    font-size: 14px;
    padding-bottom: 10px;
  }
}

.right_title h5 {
  font-weight: normal;
}

.right_title p {
  font-size: 12px;
  color: red !important;
  text-align: left !important;
  padding: 5px;
}

.none-border p {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
